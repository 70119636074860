<template>
    <v-app>
        <v-row align="center" class="top-bar">
            <v-col cols="6" class="mt-5">
                <v-btn class="purple-btn" @click="toggleWindow">
                    <v-icon left v-if="windowState === 'overview'">mdi-cog</v-icon>
                    <v-icon left v-else>mdi-view-dashboard</v-icon>
                    <span class="text-h6" v-if="windowState === 'overview'">Settings</span>
                    <span class="text-h6" v-else>Dashboard</span>
                </v-btn>
            </v-col>
            <v-col cols="6" class="mt-5" v-if="windowState === 'overview'">
                <VueDatePicker v-model="date" @update:model-value="handleDate" :max-date="currentDate" :format="format"
                    range />
            </v-col>
        </v-row>
        <transition :name="transitionName" mode="out-in">
            <div class="fixed-container" :key="windowState">
                <div v-if="windowState === 'overview'">
                    <v-row align="center" class="date-picker-row mt-2">
                        <v-col cols="12">
                            <v-select class="deviceTag mt-0" :items="deviceStore.getDevices" v-model="selectedDevice"
                                item-title="name" item-value="id" @update:model-value="updateDevice" outlined dense
                                hide-details label="Select device">
                            </v-select>
                        </v-col>
                    </v-row>

                    <div class="updatedTime mt-5 ml-2 text-center" v-if="windowState === 'overview'">{{ updatedTime }}
                    </div>
                    <v-row class="relay-status-container mt-2 justify-center">
                        <v-col v-for="relay in relayList" :key="relay.id" cols="12" md="8">
                            <v-card class="relay-card pa-5" elevation="0">
                                <v-row align="center" justify="space-between">
                                    <v-col cols="auto">
                                        <h2 class="relay-name">{{ relay.name }}</h2>
                                    </v-col>

                                    <v-card class="relay-card" elevation="0">
                                        <v-col cols="auto" class="relay-status">
                                            <v-icon
                                                :class="relay.localRelayStatus ? 'status-green' : 'status-grey'">mdi-circle</v-icon>
                                            <h3 :class="relay.localRelayStatus ? 'status-green' : 'status-grey'">
                                                {{ relay.localRelayStatus ? 'ON' : 'OFF' }}
                                            </h3>
                                        </v-col>
                                    </v-card>

                                    <v-card class="relay-card" id="countdown" elevation="0">
                                        <v-col cols="auto" class="relay-countdown">
                                            <h3
                                                :class="relay.offTime ? (relay.localRelayStatus ? 'countdown-green' : 'countdown-black') : 'countdown-black'">
                                                24
                                            </h3>
                                        </v-col>
                                    </v-card>

                                    <v-card class="relay-card" elevation="0">
                                        <v-col cols="auto" class="relay-countdown">
                                            <h3
                                                :class="relay.offCountDown ? (relay.localRelayStatus ? 'countdown-green' : 'countdown-black') : 'countdown-black'">
                                                C
                                            </h3>
                                        </v-col>
                                    </v-card>
                                </v-row>
                            </v-card>
                        </v-col>
                    </v-row>



                    <v-row class="section-container mt-0">
                        <v-col>
                            <ClientOnly>
                                <v-progress-circular v-if="isLoading" indeterminate color="primary" size="20"
                                    class="ml-2" />
                                <BirdnestTabDataDisplay v-if="temperature !== null && humidity !== null"
                                    :temperature="temperature" :humidity="humidity" />
                                <BirdnestTabTemperatureChart class="mt-5" v-if="tempSeries" :lineSeries="tempSeries" />
                                <BirdnestTabHumidityChart class="mt-5" v-if="humiditySeries"
                                    :lineSeries="humiditySeries" />
                            </ClientOnly>
                        </v-col>
                    </v-row>
                </div>
                <div v-else>
                    <v-row align="center">
                        <v-col>
                            <LazyBirdnestTabSetting2 :serial_number="selectedDeviceDetails.serial_number ?? ''"
                                :deviceId="selectedDeviceDetails.id ?? ''" />
                        </v-col>
                    </v-row>
                </div>
            </div>
        </transition>
    </v-app>
</template>


<script setup lang="ts">
import { endOfDay, formatDistanceToNow, startOfDay } from 'date-fns';
import { useDateStore } from '~/stores/dateStore';
import { useDeviceStore } from '~/stores/deviceStore';
import { useAqeconStore } from '~/stores/aqeconStore';
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import { useDeviceSettingStore } from '~/stores/deviceSettingStore';


// Pinia's states
const deviceStore = useDeviceStore();
const dateStore = useDateStore();
const aqeconStore = useAqeconStore();
const deviceSettingStore = useDeviceSettingStore();

const date = ref([startOfDay(new Date()), endOfDay(new Date())]);
const format = "dd/MM/yyyy";
const currentDate = endOfDay(new Date());

const { fetchDevices, getDevices } = deviceStore;

const isOverview = ref<boolean>(true);
const selectedDevice =  ref('');
const temperature = ref<number | null>(null);
const humidity = ref<number | null>(null);
const transitionName = ref<string>('slide-up');
const relayList = ref<any[]>();

const updatedTime = ref<string | Date | null>(null);
const refreshInterval = ref(null);
const isLoading = ref(false);
const windowState = ref<string>('overview');

const tempData = ref<any[]>([]);

const tempSeries = computed(() => {
    return [
        {
            name: 'Temperature',
            data: tempData.value,
        },
    ];
});

const humidityData = ref<any[]>([]);
const humiditySeries = computed(() => {
    return [
        {
            name: 'Humidity',
            data: humidityData.value,
        },
    ];
});

const selectedDeviceDetails = computed(() => {
  if (selectedDevice.value && deviceStore.getDevices.length > 0) {
    const device = deviceStore.getDevices.find(d => d.id === selectedDevice.value);
    return device ? { id: device.id, serial_number: device.serialNumber } : {};
  }
  return {};
});


const timeoutId = ref<any | null>(null);
function toggleWindow() {
    if (windowState.value === 'overview') {
        transitionName.value = 'slide-up'; 
        windowState.value = 'settings';

        timeoutId.value = setTimeout(() => {
            windowState.value = 'overview';
            localStorage.setItem('windowState', 'overview');
        }, 3600000);
        
    } else {
        transitionName.value = 'slide-down';
        windowState.value = 'overview';

        if (timeoutId.value) {
            clearTimeout(timeoutId.value);
            timeoutId.value = null;
        }
    }

    // Save window state in localStorage
    localStorage.setItem('windowState', windowState.value);
}
async function updateDevice() {
   if (windowState.value === 'overview') {
    await updateMeasurementData(selectedDevice.value);  
    await loadRelaySettings();
  }
}


function createQuery() {
    const query = {
        deviceId: selectedDevice.value,
        fromTime: new Date(dateStore.getTimerangeFrom),
        toTime: new Date(dateStore.getTimerangeTo),
    };
    return query;
}

async function loadRelaySettings() {
    if (!selectedDevice.value) return;
    isLoading.value = true;
    await deviceSettingStore.fetchDeviceSettings({ deviceId: selectedDevice.value });
    const deviceSettings = deviceSettingStore.getDeviceSettings.find(setting => setting.name === "relaySetting");
    
    if (deviceSettings) {
        relayList.value = Object.keys(deviceSettings.data.relaySetting).map((key, index) => {
            const relay = deviceSettings.data.relaySetting[key];
            return {
                id: index + 1,
                name: `Relay ${index + 1}`,
                localRelayStatus: relay.state !== 0,
                offTime: relay.imode === 1 || relay.imode === 3,
                offCountDown: relay.imode === 2 || relay.imode === 3,
            };
        });
    }
    isLoading.value = false;
}

const getRelayMode = (relay) => {
    if (relay.offTime && relay.offCountDown) return "24-hour & Countdown";
    if (relay.offTime) return "24-hour";
    if (relay.offCountDown) return "Countdown";
    return "Inactive";
};


async function updateMeasurementData(deviceId: string) {
    const query = createQuery();

    if (!deviceId) {
        console.error('No device ID provided');
        return;
    }

    isLoading.value = true;
    const startTime = Date.now();

    try {
        // Fetch the measurements for the selected device
        await aqeconStore.fetchEnvironmentMeasurements(query);
        const measurements = aqeconStore.getEnvironmentMeasurements;

        if (!measurements || measurements.length === 0) {
            temperature.value = null;
            humidity.value = null;
            tempData.value = [];
            humidityData.value = [];
            return;
        }
        

        const latestMeasurement = measurements[measurements.length - 1];

        

        temperature.value = Number(latestMeasurement.temperature);
        humidity.value = Number(latestMeasurement.humidity);


        // Update the timestamp for the most recent update
        const latestTimestamp = new Date(latestMeasurement.timestamp);
        updatedTime.value = latestTimestamp ? 'Updated ' + formatDistanceToNow(latestTimestamp, { addSuffix: true }) : 'No recent updates';

        // Update chart data using the entire dataset
        tempData.value = measurements.map(m => ({
            x: new Date(m.timestamp).getTime(),
            y: m.temperature,
        }));

        humidityData.value = measurements.map(m => ({
            x: new Date(m.timestamp).getTime(),
            y: m.humidity,
        }));

      

    } catch (error) {
        console.error("Error fetching measurements:", error);
    }finally {
        const elapsedTime = Date.now() - startTime;
        const delay = Math.max(1000 - elapsedTime, 0); 

        setTimeout(() => {
            isLoading.value = false;
        }, delay);
    }
}

async function handleDate(newDate: [Date, Date]) {
    if (deviceStore.getDevices.length > 0) {
        if (!selectedDevice.value && deviceStore.getDevices[0]) {
            selectedDevice.value = deviceStore.getDevices[0].id;  // Set default device
        }

        if (Array.isArray(newDate) && newDate.length === 2) {
            dateStore.updateTimerange(newDate[0], newDate[1]);  // Set the new date range in the store
        }

        await updateMeasurementData(selectedDevice.value);  // Fetch data with the selected device and date range
    } else {
        console.error('No devices available');
    }
}



onMounted(async () => {
    try {
        const savedState = localStorage.getItem('windowState');
    if (savedState) {
        windowState.value = savedState;
    }

    if (windowState.value === 'settings') {
        timeoutId.value = setTimeout(() => {
            windowState.value = 'overview';
            localStorage.setItem('windowState', 'overview');
        }, 3600000);
    }

        await fetchDevices({ limit: 10, page: 1, type: 'AQCON' });
        await handleDate(date.value);

        if (deviceStore.getDevices.length > 0) {
            selectedDevice.value = deviceStore.getDevices[0].id; 
            await updateMeasurementData(selectedDevice.value);
            await loadRelaySettings();
        }

        refreshInterval.value = setInterval(async () => {
            if (selectedDevice.value) {
                await updateMeasurementData(selectedDevice.value);
                await loadRelaySettings();
            }
        }, 15000); 
    } catch (error) {
        console.log(error);
    }
});

onUnmounted(() => {
    if (refreshInterval.value) {
        clearInterval(refreshInterval.value);
        refreshInterval.value = null;
    }
});

watch(selectedDevice, async (newDeviceId) => {
    if (newDeviceId) {
        await updateMeasurementData(newDeviceId);
        await loadRelaySettings();
    }
});
</script>

<style scoped>
@import '/assets/_birdnestOverview.scss';

.relay-card {
    border: 1px solid #787878;
    text-align: center;
}

.relay-name {
    font-size: 18px;
    color:#787878
}

.relay-status {
    display: flex;
    align-items: center;
    font-size: 16px;
    text-align: center;
    justify-content: center;
    width:80px;
    height: 30px
}

.relay-status v-icon {
    margin-right: 5px;
}

.relay-countdown {
    display: flex;
    align-items: center;
    font-size: 16px;
    text-align: center;
    justify-content: center;
    width:80px;
    height: 30px
}

.countdown-green, .status-green {
    color: #38DC35;
}

.countdown-black {
    color: #787878;
}
</style>

<template>
  <div class="charts">
    <v-row>
      <v-col cols="12">
        <v-card class="custom-card bg-surface" variant="outlined" rounded="lg">
          <v-card-text class="pb-2">
            <div class="d-flex justify-space-between align-center">
              <h5 class="text-h5 mb-0 card-title">{{ options.chart.title }}</h5>
            </div>
          </v-card-text>
          <v-card-item class="pt-0">
            <apexchart :height="options.chart.height" :type="options.chart.type" :options="options" :series="lineSeries" />
          </v-card-item>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script setup>
const props = defineProps(["lineSeries"]);

const options = {
  chart: {
    title: 'Temperature',
    type: 'area',
    height: 250,
    width: '100%',
    fontFamily: 'Nunito Sans, sans-serif',
    id: 'temp',
    group: 'aq',
  },
  colors: ['#FF0000'],
  dataLabels: {
    enabled: false,
  },
  yaxis: {
    labels: {
      formatter: (val) => val.toFixed(0) + " °C",
      style: {
        fontFamily: 'Nunito Sans, sans-serif',
        cssClass: 'apexcharts-yaxis-label',
      },
    },
  },
  stroke: {
    curve: 'smooth',
    width: 1,
  },
  fill: {
    type: 'gradient',
    gradient: {
      shadeIntensity: 1,
      type: 'vertical',
      inverseColors: false,
      opacityFrom: 0.5,
      opacityTo: 0,
    },
  },
  grid: {
    strokeDashArray: 4,
  },
  xaxis: {
    type: 'datetime',
    labels: {
      datetimeUTC: false,
    },
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
    tooltip: {
      enabled: false,
    },
  },
  tooltip: {
    enabled: true,
    shared: false,
    x: {
      format: 'dd MMM yyyy hh:mm:ss tt',
    },
    y: {
      formatter: (val) => val.toFixed(2) + ' °C',
    },
  },
};
</script>

<style scoped>
.custom-card {
  border-color: #787878 !important;
  border-width: 1px;
  border-style: solid;
  margin: 1rem;
}

.card-title {
  color: #231F20;
  font-family: 'Nunito Sans', sans-serif;
}

.apexcharts-yaxis-label {
  transform: translateX(-10px);
}
</style>

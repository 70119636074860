<template>
  <v-row class="metrics-row" justify="center">
    <v-col class="data-col">
      <v-card class="data" outlined>
        <v-card-title class="title">Temperature</v-card-title>
        <v-card-text>
          <div class="value">{{ props.temperature }}</div>
          <div class="unit"><span>°C</span></div>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col class="data-col">
      <v-card class="data" outlined>
        <v-card-title class="title">Humidity</v-card-title>
        <v-card-text>
          <div class="value humidity">{{ props.humidity }}</div>
          <div class="unit"><span>%RH</span></div>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script setup>
const props = defineProps({
  temperature: {
    type: Number,
    required: true
  },
  humidity: {
    type: Number,
    required: true
  }
});

watch(() => [props.temperature, props.humidity], ([newTemp, newHum]) => {
  console.log('Temperature updated:', newTemp);
  console.log('Humidity updated:', newHum);
});
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');
@import '/assets/_birdnestDataDisplay.scss';

.value {
  font-size: 1.8rem;
  font-weight: bold;
}

.unit {
  font-size: 1rem;
  color: grey;
}
</style>

import { defineStore } from 'pinia';
import type { DeviceSetting } from '@/types/deviceSettingTypes';

export const useDeviceSettingStore = defineStore('DeviceSetting', () => {
    const deviceSettings = ref<DeviceSetting[]>([]);
    const deviceSettingTotal = ref(0);
    const token = useCookie<string>('token');

    const getDeviceSettings = computed(() => deviceSettings.value);
    const getDeviceSettingTotal = computed(() => deviceSettingTotal.value);


    async function fetchDeviceSettings(params: { deviceId?: string }) {
        try {
            const response = await $fetch.raw('/api/v1/user/device_settings', {
                params: {
                    deviceId: params.deviceId
                },
                headers: {
                    Authorization: `Bearer ${token.value}`,
                },
            });

            deviceSettings.value = response._data as any;
            deviceSettingTotal.value = parseInt(response.headers.get('X-Total-Items') || '');
        } catch (error) {
            console.error("Error fetching device settings:", error);
        }
    }


    async function createDeviceSetting(payload: any) {
        try {

            const response = await $fetch('/api/v1/user/device_settings', {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token.value}`,
                },
                body: payload,
            });

            deviceSettings.value.push(response as DeviceSetting);
            deviceSettingTotal.value += 1;

            console.log("Device setting created successfully:", response);
            return response;
        } catch (error) {
            console.error("Error creating device setting:", error);
            throw error;
        }
    }


    async function updateDeviceSetting(id: string, payload: any) {


        const response = await $fetch(`/api/v1/user/device_settings/${id}`, {
            method: 'PUT',
            headers: {
                Authorization: `Bearer ${token.value}`
            },
            body: payload
        });

        return response;

    }



    async function deleteDeviceSetting(id: string) {
        await $fetch(`/api/v1/user/device_settings/${id}`, {
            method: 'DELETE',
            headers: {
                Authorization: `Bearer ${token.value}`
            },
        });
        deviceSettings.value = deviceSettings.value.filter(setting => setting.id !== id);
        deviceSettingTotal.value -= 1;
    }

    return {
        deviceSettings,
        deviceSettingTotal,
        getDeviceSettings,
        getDeviceSettingTotal,
        fetchDeviceSettings,
        createDeviceSetting,
        updateDeviceSetting,
        deleteDeviceSetting
    };
});